<template>
    <div class="login-box">
        <div class="title">{{ typeLogin == 0 ? $t('login.login') : typeLogin == 1 ? $t('login.zhuce') : $t('login.wangjips')
        }}
        </div>
        <div class="login">
            <input type="text" v-model="phoneNumber" :placeholder="$t('login.inputPhoneandEmier')" />
            <input type="password" v-model="password" :placeholder="$t('login.inputPs')" />
            <div class="code" v-if="typeLogin != 0">
                <input type="text" v-model="inputCode" :placeholder="$t('login.inputCode')" />
                <span @click="getCode">{{ code }}</span>
            </div>
            <div class="active" v-if="typeLogin == 0">
                <span @click="typeLogin = 1">{{ $t('login.quzhuce') }}</span>
                <span @click="typeLogin = 2">{{ $t('login.wangjips') }}</span>
            </div>
            <div class="button" @click="handleChange">{{ typeLogin == 0 ? $t('login.login') : typeLogin == 1 ?
                $t('login.zhuce') : $t('button.queding') }}</div>
            <div class="agreement">
                <span>{{ $t('login.agreementTitle') }}</span>
                <div> <span @click="navTo('/vipSystem')">《{{ $t('login.quanyi') }}》</span> {{ $t('login.and') }} <span @click="navTo('/About/privacy')">《{{ $t('login.tiaokun') }}》</span>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
import { sendVerificationCode, sendEmailCode, register, login,resetPassword } from '@/api/main.js'
import {mapMutations} from 'vuex'
export default {
    inject: ['reload'],
    data() {
        return {
            code: this.$t('login.code'),
            preventClick: true,
            typeLogin: 0,//0 登录 1注册 2.忘记密码
            phoneNumber: '',

            password: '',
            backCode: '-1',//验证码
            inputCode:''//输入code
        } 
    },
    mounted() {
    },
    methods: {
        ...mapMutations(['saveUserInfo']),
        navTo(path){
            this.$router.push(path)
        },
        login(){
            var obj = {}
                if (this.isChinese()) {//手机号注册
                    // if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
                    //     this.$message({
                    //         message: '手机号码有误',
                    //         type: 'warning'
                    //     });
                    //     return;
                    // }
					if (this.phoneNumber.length!=9) {
					    this.$message({
					        message: '手机号码有误',
					        type: 'warning'
					    });
					    return;
					}
                    if (!this.password) {
                        this.$message({
                            message: '请输入密码',
                            type: 'warning'
                        });
                        return;
                    }
                    obj.phoneNumber = this.phoneNumber
                }else{
                    if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.phoneNumber)){
                        this.$message({
                            message: 'Incorrect email address',
                            type: 'warning'
                        });
                        return;
                    }
                    if (!this.password) {
                        this.$message({
                            message: 'Please enter your password first',
                            type: 'warning'
                        });
                        return;
                    }
                    obj.eMail = this.phoneNumber;
                }
                login({
                    ...obj,
                    password: this.password
                }).then(res => {
                  
                    if (this.isChinese()) {
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                    } else {  
                        this.$message({
                            message: 'Login successfully',
                            type: 'success'
                        });
                    }
                   
                    this.saveUserInfo(res.data);
                    this.$router.back()
                    var titme = setTimeout(()=>{
                        this.reload();
                        clearTimeout(titme)
                    },0)
                   
                })
        },
        register(){
            var obj = {}
            if (this.isChinese()) {//手机号注册
                // if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
                //     this.$message({
                //         message: '手机号码有误',
                //         type: 'warning'
                //     });
                //     return;
                // }
				if (this.phoneNumber.length!=9) {
				    this.$message({
				        message: '手机号码有误',
				        type: 'warning'
				    });
				    return;
				}
                if (!this.password) {
                    this.$message({
                        message: '请输入密码',
                        type: 'warning'
                    });
                    return;
                }
                if(this.inputCode!=this.backCode){
                    this.$message({
                        message: '输入验证码有误',
                        type: 'warning'
                    });
                    return;
                }
                obj.phoneNumber = this.phoneNumber
            } else {//邮箱注册
                if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.phoneNumber)){
                    this.$message({
                        message: 'Incorrect email address',
                        type: 'warning'
                    });
                    return;
                }
                if (!this.password) {
                    this.$message({
                        message: 'Please enter your password first',
                        type: 'warning'
                    });
                    return;
                }
                if(this.inputCode!=this.backCode){
                    this.$message({
                        message: 'Error in entering verification code',
                        type: 'warning'
                    });
                    return;
                }
                obj.eMail = this.phoneNumber;
            }

            register({
                ...obj,
                password: this.password
            }).then(res => {
                this.showMessage(res)
                if(res.code==0){
                    this.typeLogin = 0
                    this.password = ''
                    this.inputCode = ''
                }
            })
        },
        setPassword(){
            var obj = {}
            if (this.isChinese()) {//手机号注册
                // if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
                //     this.$message({
                //         message: '手机号码有误',
                //         type: 'warning'
                //     });
                //     return;
                // }
				if (this.phoneNumber.length!=9) {
				    this.$message({
				        message: '手机号码有误',
				        type: 'warning'
				    });
				    return;
				}
                if (!this.password) {
                    this.$message({
                        message: '请输入密码',
                        type: 'warning'
                    });
                    return;
                }
                if(this.inputCode!=this.backCode){
                    this.$message({
                        message: '输验证码有误',
                        type: 'warning'
                    });
                    return;
                }
                obj.phoneNumber = this.phoneNumber
            } else {//邮箱注册
                if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.phoneNumber)){
                    this.$message({
                        message: 'Incorrect email address',
                        type: 'warning'
                    });
                    return;
                }
                if (!this.password) {
                    this.$message({
                        message: 'Please enter your password first',
                        type: 'warning'
                    });
                    return;
                }
                if(this.inputCode!=this.backCode){
                    this.$message({
                        message: 'Error in entering verification code',
                        type: 'warning'
                    });
                    return;
                }
                obj.eMail = this.phoneNumber;
            }
            resetPassword({
                ...obj,
                password: this.password
            }).then(res => {
                this.showMessage(res)
                if(res.code==0){
                    this.typeLogin = 0
                    this.password = ''
                    this.inputCode = ''
                }
            })
        },
        handleChange() {
            // 登录
            if (this.typeLogin == 0) {
                this.login()
            } else if (this.typeLogin == 1) {
                //注册
              this.register();
            } else {
                //忘记密码
                this.setPassword()
            }
        },
        getCode() {
            var yx = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            var phoe = /^1[3456789]\d{9}$/;
            if (this.isChinese()) {
                // if (!phoe.test(this.phoneNumber)) {
                //     this.$message({
                //         message: '手机号码有误',
                //         type: 'warning'
                //     });
                //     return;
                // }
				if (this.phoneNumber.length!=9) {
				    this.$message({
				        message: '手机号码有误',
				        type: 'warning'
				    });
				    return;
				}
                if (this.preventClick) {
                    this.preventClick = false;
                    sendVerificationCode({
                        phoneNumber: this.phoneNumber
                    }).then(res => {
                        this.backCode = res.data
                        this.code = 60;
                        let time = setInterval(() => {
                            this.code -= 1
                            if (this.code < 1) {
                                clearInterval(time);
                                this.code = '获取验证码';
                                this.preventClick = true;
                            }
                        }, 1000);
                    })
                }
            } else {
                if (!yx.test(this.phoneNumber)) {
                    this.$message({
                        message: 'Incorrect email address',
                        type: 'warning'
                    });
                    return;
                }
                if (this.preventClick) {
                    this.preventClick = false;
                    sendEmailCode({
                        eMail: this.phoneNumber
                    }).then(res => {
                        this.backCode = res.data
                        this.code = 60;
                        let time = setInterval(() => {
                            this.code -= 1
                            if (this.code < 1) {
                                clearInterval(time);
                                this.code = 'get code';
                                this.preventClick = true;
                            }
                        }, 1000);
                    })
                }

            }


        },
    }
}
</script>
<style lang="scss" scoped>
.login-box {
    .title {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding: 20px;
        text-align: center;
    }

    .login {
        width: 660px;
        height: 490px;
        border: 1px solid #DDDDDD;
        margin: 0 auto;
        padding: 60px 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        input {
            width: 500px;
            height: 48px;
            border: 1px solid #DDDDDD;
            padding: 13px 20px;
            font-size: 16px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }

        .code {

            position: relative;

            span {
                position: absolute;
                right: 20px;
                margin-top: 13px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #000000;
                cursor: pointer;
            }
        }

        .active {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 500px;

            span {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .button {
            width: 500px;
            padding: 14px 0px;
            text-align: center;
            background: #000000;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            border: 1px solid #000000;
            cursor: pointer;
            transition: all .2s ease .2s;
        }

        .button:hover {
            background: #FFFFFF;
            color: #000000;
            transition: all .2s ease .2s;
        }

        .agreement {
            text-align: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            div {
                span {
                    color: #3590D2;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>